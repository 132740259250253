<template>
  <div class="checkedV1" @click="change">
    <div class="checkedV1_dot" :class="{ 'checkedV1_dot-active': value }">
      <img v-if="value" :src="require('@STATIC/image/icons/checked.png')" />
    </div>
    <div class="checkedV1_content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "checkedV1",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: null, //判断用户点击的那个checked
    params: null, //携带的数据
  },
  mounted() {},

  methods: {
    change() {
      this.$emit("input", !this.$props.value);
      this.$emit("change", {
        checked: !this.$props.value, //改变后的值
        id: this.$props.id, //id默认没有
        params: this.$props.params, //携带的参数
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@include b(checkedV1) {
  display: flex;
  @include e(dot) {
    display: inline-flex;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    box-shadow: inset 0 0 1px 1px #d1d1d1;
    border-radius: 50%;
    & > img {
      width: 16px;
      height: 16px;
    }
    @include m(active) {
      box-shadow: none !important;
    }
  }
  @include e(content) {
    display: inline-flex;
    flex-grow: 1;
  }
}
</style>
